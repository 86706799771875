<template lang="pug">
v-dialog(v-model="dialogContact", max-width="400", persistent)
  v-card
    v-btn.float-right(
      color="darken-1",
      text="",
      fab="",
      @click="closeDialog",
      :ripple="false"
    ) 
      v-icon(color="#ABC9E3") mdi-close
    v-card-title.clear.flex-column.justify-center
      .text-h5 聯絡我們
      .mail_icon.mt-5
        font-awesome-icon(:icon="['fas', 'envelope-open-text']")

    v-card-text.text-center.mt-3
      .input_container.mt-6
        input(
          required,
          type="text",
          name="title",
          placeholder="主旨",
          v-model="title"
        )
      .input_container.mt-6
        textarea(
          required,
          type="text",
          name="content",
          placeholder="聯絡內容",
          v-model="description"
        )
    v-card-actions
      v-spacer
      v-btn(color="darken-1", text="", @click="contactUs") 送出表單
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
</template>

<script>
import { contactUs } from "@/api/error_report";
import { validStringEmpty } from "@/utils/validate";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";

export default {
  name: "DialogContact",
  props: {
    dialogContact: Boolean,
  },
  components: {
    DialogSuccess,
    DialogError,
    DialogLoading,
  },
  data() {
    return {
      title: "",
      description: "",

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
    };
  },
  methods: {
    contactUs() {
      if (validStringEmpty(this.title) || validStringEmpty(this.description)) {
        this.showErrorDialog("錯誤", "請填寫聯絡內容");
        return;
      }
      let data = {
        title: this.title,
        description: this.description,
      };
      this.showLoadingDialog("傳送中...");
      contactUs(data)
        .then(() => {
          this.closeLoadingDialog();
          this.showSuccessDialog("傳送成功", "請等待我們閱讀完後聯絡");
          setTimeout(() => {
            this.closeSuccessDialog();
            this.closeDialog();
          }, 2000);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("傳送失敗", error.response.data.error_msg);
        });
    },
    closeDialog() {
      this.$emit("closeContactDialog");
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.fa-envelope-open-text {
  width: 50px;
  height: 50px;
  color: $primary;
}

input,
textarea {
  border-radius: 3px;
  background: #ffffff;
  border: 1px solid #487aa4;
}

textarea {
  height: 100px;
}
</style>
