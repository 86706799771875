<template lang="pug">
.member_center(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  v-container.container--fluid.member_center_header.pa-0.pb-4
    v-container
      v-row.mt-5
        v-col.text-center(cols="12")
          h5.normal_title_h5 會員專區
      v-divider.mt-3
      v-row.mt-4.text-center.text-md-left
        v-col.d-flex.align-center.justify-center(cols="12", md="auto")
          .user_basis.user_avater.d-flex.flex-grow-1.align-center.justify-center(
            @click="onCameraClick"
          )
            img.avater_lg(v-if="userInfo.photo == null", :src="gcAvatar")
            img.avater_lg(v-else, :src="userInfo.photo")
            .camera_btn
              v-icon mdi-camera
          input.d-none(
            ref="imageUpload",
            type="file",
            accept="image/*",
            @change="imageUpload"
          ) 
        v-col.d-flex.align-center.flex-grow-1.flex-column.flex-md-row.justify-space-between(
          cols="12",
          md="auto"
        )
          .user_info.ml-4.w-md-auto.w-auto
            span.text-overline 會員 EMAIL 帳號：
            p.user_email {{ userInfo.email }}
          .btns
            .contact_btn.rounded-sm.my-2.text-center(
              @click="showContactDialog"
            ) 聯絡我們
            .logout_btn.rounded-sm.my-2.text-center(@click="toLogout") 登出
      v-row.justify-end.position-relative.mt-10
        v-divider.position-absolute.bottom-0.w-100.left-0
        .tabs
          v-tabs.sheet-s-border.position-relative(
            v-model="tab",
            ripple="false"
          )
            v-tabs-slider(hidden="true")
            v-tab(:ripple="false") 個人資料
            v-tab(:ripple="false") 我的錢包
  v-container.container--fluid.pa-0(v-if="tab == 0")
    v-row.fill_up_profile_alert(
      v-if="isUserInfoReady && !tempUserInfo.is_profile_complete && !getProfileFilled"
    )
      v-col.text-center(cols="12")
        svg-icon(iconClass="coin_icon", className="coin_icon_sm")
        span.ml-2 填寫完整資料領取100 Fantasy Coins
    v-row.get_coin_alert(
      v-if="isUserInfoReady && !tempUserInfo.is_profile_complete && getProfileFilled"
    )
      v-col.text-center(cols="12")
        svg-icon(iconClass="coin_icon", className="coin_icon_sm")
        span.ml-2(@click="setProfileCompelete") 立即領取100 Fantasy Coins
    v-container
      v-row.mt-5(justify="space-between")
        v-col(cols="auto")
          h5.normal_title_h5 個人資料
        v-col(cols="auto")
          .btn.edit_btn(v-if="!isEditStatus", @click="editUserInfo")
            v-icon(small) mdi-pencil
            span.edit.ml-1 編輯
          .btns(v-if="isEditStatus")
            .btn.btn_water_blue(@click="updateUserInfo") 編輯完成
            .btn.btn_gray_hollow.ml-2(@click="cancel") 取消
      v-row
        v-col(cols="12")
          ul
            li 
              span.info_title.text-overline(
                :class="{ required: isEditStatus }"
              ) 名字
              input(
                maxlength="30",
                v-if="isEditStatus",
                placeholder="請填寫姓名",
                v-model="tempUserInfo.name"
              )
              span.info_content(v-if="!isEditStatus") {{ userInfo.name }}
            li 
              span.info_title.text-overline(
                :class="{ required: isEditStatus }"
              ) 暱稱
              input(
                maxlength="30",
                v-if="isEditStatus",
                placeholder="請填寫暱稱",
                v-model="tempUserInfo.nickname"
              )
              span.info_content(v-if="!isEditStatus") {{ userInfo.nickname }}
            li 
              span.info_title.text-overline(
                :class="{ required: isEditStatus }"
              ) 手機號碼
              span.info_content(v-if="!isEditStatus") {{ userInfo.phone }}
              .info_content(v-if="isEditStatus && userInfo.phone != null") {{ userInfo.phone }}
              .info_content(v-if="isEditStatus && userInfo.phone == null")
                router-link(to="/VerifyMobile") 前往認證
            li 
              span.info_title.text-overline 生日
              .date_picker(v-if="isEditStatus", @click="openDateDialog") {{ getBirthdate }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.birthdate }}

              //- .phone_verify
              //-   input(
              //-     v-if="isEditStatus",
              //-     placeholder="輸入密碼",
              //-     v-model="tempUserInfo.name"
              //-   ) 
              //-   input.mt-3(
              //-     v-if="isEditStatus",
              //-     placeholder="再次輸入密碼",
              //-     v-model="tempUserInfo.name"
              //-   ) 
            li 
              span.info_title.text-overline 性別
              select#plan(
                v-if="isEditStatus",
                v-model="tempUserInfo.sex",
                name="plan"
              )
                option(v-for="(sex, index) in sexList", :value="sex") {{ sex }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.sex }}
            li 
              span.info_title.text-overline 居住城市
              select(v-if="isEditStatus", v-model="tempUserInfo.city")
                option(v-for="(city, index) in cityList", :value="city") {{ city }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.city }}
            li 
              span.info_title.text-overline 籃球程度
              select(v-if="isEditStatus", v-model="tempUserInfo.degree")
                option(value="none", selected, disabled) 請選擇
                option(v-for="(degree, index) in degreeList", :value="degree") {{ degree }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.degree }}
            li 
              span.info_title.text-overline 職業
              select(v-if="isEditStatus", v-model="tempUserInfo.profession")
                option(
                  v-for="(profession, index) in professionList",
                  :value="profession"
                ) {{ profession }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.profession }}
            li 
              span.info_title.text-overline 月收入
              select(v-if="isEditStatus", v-model="tempUserInfo.month_income")
                option(
                  v-for="(month_income, index) in monthIncomeList",
                  :value="month_income"
                ) {{ month_income }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.month_income }}
            li 
              span.info_title.text-overline 籃球經驗
              select(
                v-if="isEditStatus",
                v-model="tempUserInfo.basketball_experience"
              )
                option(
                  v-for="(basketball_experience, index) in basketballExperienceList",
                  :value="basketball_experience"
                ) {{ basketball_experience }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.basketball_experience }}
            li 
              span.info_title.text-overline 如何得知GC
              select(v-if="isEditStatus", v-model="tempUserInfo.contact_from")
                option(
                  v-for="(contact_from, index) in contactFromList",
                  :value="contact_from"
                ) {{ contact_from }}
              span.info_content(v-if="!isEditStatus") {{ userInfo.contact_from }}
            li
              span.info_title.text-overline 喜好
              .pleague.mt-6.pa-4.border.rounded(
                v-if="isEditStatus",
                v-for="(league, index) in favoriteLeagueList"
              )
                h2.ml-4 {{ league.league_name }}
                v-card.mx-auto.mt-2(elevation="0")
                  v-row.wrapper.d-flex.pa-4.justify-center
                    v-col(cols="12", md="2")
                      img.w-100(:src="league.photo.image")
                    v-col(cols="12", md="10")
                      v-select(
                        @change="getFavoriteLeague",
                        v-model="selectTeamLists[index]",
                        :items="league.teams",
                        chips,
                        :item-text="'name'",
                        return-object,
                        label="請選擇球隊",
                        multiple
                      )
              .pleague.mt-6.border.rounded(
                v-if="!isEditStatus",
                v-for="(league, index) in userInfo.favorites"
              )
                v-row.pa-4.flex-column
                  h2.ml-4 {{ league.league_name }}
                  v-divider.mt-1
                v-row.wrapper.d-flex.pa-4.justify-center
                  v-col(cols="12", md="2")
                    img.w-100(v-if="league.photo", :src="league.photo.image")
                  v-col(cols="12", md="10")
                    v-chip.ma-2(
                      v-for="(team, index) in league.team",
                      :key="index"
                    ) {{ team.name }}
  v-container.container--fluid.pa-0(v-if="tab == 1")
    v-container.d-flex.wallet_header.align-center.px-10.mt-10
      v-row.align-center
        svg-icon(iconClass="coin_icon", className="coin_icon")
        h2.ml-2 我的錢包
        h3.subtitle.ml-4 Fantasy coins
    v-container.d-flex.wallet_body.pa-10
      v-row
        v-col.details_container.order-1.order-md-0
          .details.py-4.px-8
            h3.text-center 錢包收支明細
            v-divider.mt-2
            .tag_container.mt-3
              v-tabs.rounded-tab(
                background-color="transparent",
                ripple="false",
                v-model="walletTab"
              )
                v-tabs-slider(hidden)
                v-tab(:ripple="false") 全部
                v-tab.ml-2(:ripple="false") 收入
                v-tab.ml-2(:ripple="false") 支出
            .detail_item_container
              .detail_item.d-flex.pa-3.align-center(
                v-for="(detail, index) in detailData",
                :key="index"
              )
                span.date {{ formatDate(detail.date) }}
                span.item_title {{ detail.description }}
                v-spacer
                span.coin {{ detail.amount }}
            v-pagination(
              v-model="currentPage",
              :length="totalPages",
              prev-icon="mdi-menu-left",
              next-icon="mdi-menu-right",
              circle
            )
        v-col.aside.d-flex.d-md-block
          .rect.coin.pa-5
            p.rect_title 錢包餘額
            .rect_content.d-flex.justify-space-between.align-center
              h2 {{ toThousands(userInfo.fantasy_coin) }}
              span coins
          .rect.count.pa-5.ml-3.ml-md-0.mt-md-4
            p.rect_title 錢包收支紀錄
            .rect_content.d-flex.justify-space-between.align-center
              h2 {{ detailCount }}
              span 筆

  custom-footer
  dialog-success(
    :title="dialogSuccessData.title",
    @closeSuccessDialog="closeSuccessDialog",
    :dialogSuccess="dialogSuccessShow"
  )
    template(#content)
      p {{ dialogSuccessData.text }}
  dialog-error(
    :title="dialogErrorData.title",
    @closeErrorDialog="closeErrorDialog",
    :dialogError="dialogErrorShow"
  )
    template(#content)
      p {{ dialogErrorData.text }}
  dialog-loading(:dialogLoading="dialogLoadingShow")
    template(#content)
      p {{ dialogLoadingData.text }}
  dialog-contact(
    :dialogContact="dialogContactShow",
    @closeContactDialog="closeContactDialog"
  )
  v-dialog(v-model="isDialogShow", width="500")
    v-date-picker.mt-4(
      v-model="date",
      locale="zh-tw",
      full-width,
      :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)",
      @click:date="closeDateDialog"
    )
</template>

<script>
import CustomFooter from "@/components/common/Footer";
import gcAvatar from "@/assets/img/gc-avatar.png";
import DialogSuccess from "@/components/dialog/DialogSuccess";
import DialogError from "@/components/dialog/DialogError";
import DialogLoading from "@/components/dialog/DialogLoading";
import DialogContact from "@/components/dialog/DialogContact";
import { mapActions } from "vuex";
import {
  getUserInfo,
  getSexList,
  getCityList,
  getDegreeList,
  getProfessionList,
  getMonthIncomeList,
  getBasketballExperienceList,
  getContactFromList,
  updateUserInfo,
  uploadAvatar,
  setProfileCompelete,
} from "@/api/user";
import { getTransferList } from "@/api/fantasy";
import { getFavoriteLeagueData } from "@/api/league";
import { validStringEmpty } from "@/utils/validate";
import moment from "moment";

export default {
  name: "MemberCenter",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
    DialogSuccess,
    DialogError,
    DialogLoading,
    DialogContact,
  },
  data() {
    return {
      checkboxList: [],
      selectTeamLists: [],

      isEditStatus: false,
      gcAvatar,
      userInfo: {},
      tempUserInfo: {},
      favoriteLeagueList: [],
      sexList: [],
      cityList: [],
      degreeList: [],
      professionList: [],
      monthIncomeList: [],
      basketballExperienceList: [],
      contactFromList: [],
      birthdateInitial: false,
      isUserInfoReady: false,

      tab: this.$route.params.tab,
      walletTab: 0,
      detailCount: 0,
      detailData: [],
      currentPage: 1,
      totalPages: 1,
      perPage: 10,

      dialogSuccessShow: false,
      dialogSuccessData: {
        title: "",
        text: "",
      },

      dialogErrorShow: false,
      dialogErrorData: {
        title: "",
        text: "",
      },

      dialogLoadingShow: false,
      dialogLoadingData: {
        text: "",
      },
      dialogContactShow: false,
      date: null,
      isDialogShow: false,

      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "會員中心 | Game Changer",
        description: "會員中心",
        keywords: "Game changer,籃球,會員,會員中心",
        url: "https://www.gamechanger.tw/membercenter",
        image: "",
        smoType: "website",
      },
    };
  },
  watch: {
    walletTab() {
      this.detailCount = 0;
      this.detailData = [];
      this.currentPage = 1;
      this.totalPages = 1;
      this.getTransferList();
    },
    date() {
      if (this.birthdateInitial == true) {
        this.tempUserInfo.birthdate = null;
        this.birthdateInitial = false;
      } else {
        this.tempUserInfo.birthdate = this.date;
      }
    },
    currentPage() {
      this.detailData = [];
      this.getTransferList();
    },
  },
  computed: {
    getBirthdate() {
      if (
        this.tempUserInfo.birthdate != "" &&
        this.tempUserInfo.birthdate != null
      ) {
        return this.tempUserInfo.birthdate;
      } else {
        return "請選擇";
      }
    },
    getProfileFilled() {
      console.log("getProfileFilled");
      console.log("this.userInfo.phone", this.userInfo.phone);
      if (
        this.userInfo.basketball_experience != null &&
        this.userInfo.birthdate != null &&
        this.userInfo.city != null &&
        this.userInfo.contact_from &&
        this.userInfo.degree != null &&
        this.userInfo.email != null &&
        this.userInfo.month_income != null &&
        this.userInfo.name != null &&
        this.userInfo.nickname != null &&
        this.userInfo.phone != null &&
        this.userInfo.profession != null &&
        this.userInfo.sex != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    this.getUserInfo();
    this.getFavoriteLeagueData();
    this.getTransferList();
    this.getSexList();
    this.getCityList();
    this.getDegreeList();
    this.getProfessionList();
    this.getMonthIncomeList();
    this.getBasketballExperienceList();
    this.getContactFromList();
  },
  methods: {
    ...mapActions("user", ["logout", "getInfo"]),
    toLogout() {
      this.logout();
      this.$router.push({ name: "Home" });
    },
    getUserInfo() {
      getUserInfo().then((response) => {
        this.userInfo = response.data;

        console.log(this.userInfo);
        this.tempUserInfo = Object.assign({}, this.userInfo);
        this.isUserInfoReady = true;
      });
    },
    setProfileCompelete() {
      setProfileCompelete().then((response) => {
        console.log("setProfileCompelete", response);
        this.showSuccessDialog("領取成功", " 100 Fantasy Coins 已存入您的帳戶");
        this.getUserInfo();
      });
    },
    getCityList() {
      getCityList().then((response) => {
        this.cityList = response.data;
      });
    },
    getDegreeList() {
      getDegreeList().then((response) => {
        this.degreeList = response.data;
      });
    },
    getProfessionList() {
      getProfessionList().then((response) => {
        this.professionList = response.data;
      });
    },
    getMonthIncomeList() {
      getMonthIncomeList().then((response) => {
        this.monthIncomeList = response.data;
      });
    },
    getBasketballExperienceList() {
      getBasketballExperienceList().then((response) => {
        this.basketballExperienceList = response.data;
      });
    },
    getSexList() {
      getSexList().then((response) => {
        this.sexList = response.data;
      });
    },
    getContactFromList() {
      getContactFromList().then((response) => {
        this.contactFromList = response.data;
        console.log(this.contactFromList);
      });
    },
    async getTransferList() {
      this.showLoadingDialog("讀取中...");

      let type = "";
      if (this.walletTab == 0) {
        type = "ALL";
      } else if (this.walletTab == 1) {
        type = "DEPOSIT";
      } else if (this.walletTab == 2) {
        type = "WITHDRAW";
      }
      let temp = {
        state: type,
        page: this.currentPage,
        page_size: this.perPage,
      };

      await getTransferList(temp)
        .then((response) => {
          this.totalPages = response.data.total_page;
          this.detailCount = response.data.total_count;
          this.detailData = response.data.data;
        })
        .catch((error) => {
          this.showErrorDialog("讀取失敗", error.response.data.error);
          return;
        });

      this.closeLoadingDialog();
    },
    getFavoriteLeagueData() {
      getFavoriteLeagueData().then((response) => {
        this.favoriteLeagueList = response.data;
      });
    },
    async updateUserInfo() {
      if (
        validStringEmpty(this.tempUserInfo.name) ||
        validStringEmpty(this.tempUserInfo.nickname)
      ) {
        this.showErrorDialog("修改失敗", "姓名及暱稱不可為空白");
        return;
      }

      delete this.tempUserInfo.photo;
      this.showLoadingDialog("修改中...");
      this.tempUserInfo.favorites = this.getFavoriteLeague();
      console.log("this.tempUserInfo", this.tempUserInfo);
      await updateUserInfo(this.tempUserInfo)
        .then(() => {
          this.getUserInfo();
          this.closeLoadingDialog();

          this.showSuccessDialog("修改成功", "會員資料已修改");
          this.isEditStatus = false;
          setTimeout(() => {
            this.closeSuccessDialog();
          }, 1500);
        })
        .catch((error) => {
          this.closeLoadingDialog();
          this.showErrorDialog("修改失敗", error.response.data.error_msg);
        });
    },
    getFavoriteLeague() {
      let tempFavorite = [];
      for (let index in this.selectTeamLists) {
        let tempTeamList = this.selectTeamLists[index];
        let tempLeague = this.favoriteLeagueList[index];

        if (tempTeamList && tempTeamList.length > 0) {
          let temp = {
            league: tempLeague.league_id,
            league_name: tempLeague.league_name,
            team: tempTeamList,
          };
          tempFavorite.push(temp);
        }
      }
      return tempFavorite;
    },
    editUserInfo() {
      this.isEditStatus = true;
      this.checkboxList = [];
      this.selectTeamLists = [];

      for (let index in this.userInfo.favorites) {
        let temp = this.userInfo.favorites[index];
        this.checkboxList.push(temp.league);
        for (let y in this.favoriteLeagueList) {
          let tempFavorite = this.favoriteLeagueList[y];
          if (temp.league == tempFavorite.league_id) {
            this.selectTeamLists[y] = temp.team;
          }
        }
      }

      if (this.userInfo.birthdate != "" && this.userInfo.birthdate != null) {
        this.date = this.userInfo.birthdate;
      } else {
        this.birthdateInitial = true;
        this.date = new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
    },
    cancel() {
      this.tempUserInfo = Object.assign({}, this.userInfo);
      this.isEditStatus = false;
    },
    getUserSex(sex) {
      switch (sex) {
        case "MALE":
          return "男";
        case "FEMALE":
          return "女";
        default:
          return "";
      }
    },
    onCameraClick() {
      this.$refs.imageUpload.click();
    },
    imageUpload(event) {
      if (event.target.files[0]) {
        const formData = new FormData();
        formData.append("photo", event.target.files[0]);

        this.showLoadingDialog("上傳中...");
        uploadAvatar(formData)
          .then(() => {
            this.showSuccessDialog("成功", "上傳成功");
            this.getUserInfo();
            this.getInfo();
            this.closeLoadingDialog();
          })
          .catch((error) => {
            this.closeLoadingDialog();
            this.showErrorDialog("上傳失敗", error.response.data.error_msg);
          });
      }
    },
    // getAllowedDates(val) {
    //   if (val && this.seasonData.valid_dates.includes(val)) {
    //     return true;
    //   }
    //   return false;
    // },
    getDateArrowState(direction) {
      let currentDateIndex = this.seasonData.valid_dates.indexOf(this.date);
      if ("PREV" == direction) {
        if (currentDateIndex == 0) {
          return true;
        } else {
          return false;
        }
      } else if ("NEXT" == direction) {
        if (currentDateIndex == this.seasonData.valid_dates.length - 1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    openDateDialog() {
      this.isDialogShow = true;
    },
    closeDateDialog() {
      this.isDialogShow = false;
    },
    showErrorDialog(title, text) {
      this.dialogErrorData.title = title;
      this.dialogErrorData.text = text;
      this.dialogErrorShow = true;
    },
    closeErrorDialog() {
      this.dialogErrorData.title = "";
      this.dialogErrorData.text = "";
      this.dialogErrorShow = false;
    },
    showSuccessDialog(title, text) {
      this.dialogSuccessData.title = title;
      this.dialogSuccessData.text = text;
      this.dialogSuccessShow = true;
    },
    closeSuccessDialog() {
      this.dialogSuccessData.title = "";
      this.dialogSuccessData.text = "";
      this.dialogSuccessShow = false;
    },
    showLoadingDialog(text) {
      this.dialogLoadingData.text = text;
      this.dialogLoadingShow = true;
    },
    closeLoadingDialog() {
      this.dialogLoadingData.text = "";
      this.dialogLoadingShow = false;
    },
    showContactDialog() {
      this.dialogContactShow = true;
    },
    closeContactDialog() {
      this.dialogContactShow = false;
    },
    onCheckboxChanged(league) {
      if (!this.checkboxList.includes(league)) {
        this.checkboxList.push(league);
      } else {
        this.checkboxList.splice(this.checkboxList.indexOf(league), 1);
      }
    },
    toThousands(num) {
      var temp = num + "";
      var result = "";
      while (temp.length > 3) {
        result = "," + temp.slice(-3) + result;
        temp = temp.slice(0, temp.length - 3);
      }
      if (temp) {
        result = temp + result;
      }
      return result;
    },
    formatDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/style/user/member_center";
@import "@/assets/style/common/pagination";
</style>
