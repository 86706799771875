import request from "@/utils/request";
import store from "@/store";

export function playErrorReport(data) {
  return request({
    url: "/user/create_mistake_play/",
    method: "post",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    data
  });
}

export function contactUs(data) {
  return request({
    url: "/user/create_contact_us/",
    method: "post",
    headers: { Authorization: "JWT " + store.state.user.accessToken },
    data
  });
}
